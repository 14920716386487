// Fonts
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Zephyr 5.1.3
// Bootswatch
// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

// Navbar

.navbar {
  font-size: $font-size-sm;
  font-weight: 500;

  .nav-item {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .navbar-nav {
    .nav-link {
      border-radius: $border-radius;
    }
  }
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      &:hover {
        background-color: rgba(255, 255, 255, .1);
      }

      &.active {
        background-color: rgba(0, 0, 0, .5);
      }
    }
  }
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      &:hover {
        background-color: rgba(0, 0, 0, .03);
      }

      &.active {
        background-color: rgba(0, 0, 0, .05);
      }
    }
  }
}

// Buttons

.btn-secondary,
.btn-light,
.btn-outline-secondary,
.btn-outline-light {
  color: $gray-900;

  &:disabled,
  &.disabled {
    border: 1px solid shade-color($secondary, 10%);
  }
}

.btn-secondary,
.btn-outline-secondary {
  border-color: shade-color($secondary, 10%);

  &:hover,
  &:active {
    background-color: shade-color($secondary, 10%);
    border-color: shade-color($secondary, 10%);
  }
}

.btn-light,
.btn-outline-light {
  border-color: shade-color($light, 10%);

  &:hover,
  &:active {
    background-color: shade-color($light, 10%);
    border-color: shade-color($light, 10%);
  }
}

// Tables

.table {
  box-shadow: $box-shadow-lg;
  font-size: $font-size-sm;
}

thead th {
  text-transform: uppercase;
  font-size: $font-size-sm;
}

// Forms

.input-group-text {
  box-shadow: $box-shadow;
}

// Navs

.nav-tabs {
  font-weight: 500;

  .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-width: 0 0 1px;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    box-shadow: inset 0 -2px 0 $primary;
  }
}

.nav-pills {
  font-weight: 500;
}

.pagination {
  font-size: $font-size-sm;
  font-weight: 500;

  .page-link {
    box-shadow: $box-shadow;
  }
}

.breadcrumb {
  font-size: $font-size-sm;
  font-weight: 500;
  padding: 0;

  &-item {
    padding: 1rem .5rem 1rem 0;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 1rem;
}

// Indicators

.alert {
  .btn-close {
    color: inherit;
  }
}

.badge {
  &.bg-secondary,
  &.bg-light {
    color: $gray-900;
  }
}

// Containers

.list-group {
  box-shadow: $box-shadow-lg;
}

.card {
  box-shadow: $box-shadow-lg;

  &-title {
    color: inherit;
  }
}

.modal-footer {
  background-color: $gray-100;
}

.modal-content {
  box-shadow: $box-shadow-lg;
}


// Vlastní úpravy

.body-bg {
    background-color: $white;
}

// box
$border-radius-box:                     0.1rem;
$box-shadow-box:                        0 0 5px $gray-500;

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

@include media-breakpoint-up(sm) {
    .box {
        border: 1px solid $gray-300;
        border-radius: $border-radius-box;
        box-shadow: $box-shadow-box;
        padding: 1rem;
        margin-bottom: 1rem;
    }
}    

.box h1, .box h2, .box h3, .box h4 {
    font-family: 'Asap', sans-serif;
    color: $gray-800;  
} 

.main-title {
    padding-top: 0.5rem;
}

.box a {
    text-decoration: none;
}

.main a {
    font-weight: bold;
}

pre {
    font-size: 0.93rem;
}

.main pre span.akord {
    color: $red;
    font-weight: bold;
}
